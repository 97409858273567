<template>
	<div>
		<TopNav></TopNav>
		<Header></Header>
	
<div class="BGWhite" style="border-bottom: 20px solid #ffffff;">
	<div class="Wrap" style="display: block;">
		
		<div class="BuyTitle">
			<em>
				订单付款
			</em>
			<span>
				共{{OrderList.length}}个订单,请您在24小时内支付，否则订单可能被取消
			</span>
		</div>
		
		
		<div class="PayArea">
			
			
			<div class="Orders">
				<ul v-for="order in OrderList" :key="order.Id">
					<h4>
						订单号:<span>{{order.Id}}</span> (￥{{order.TotalFee}}元)
					</h4>
					<div class="DetailImgs">
						<template v-for="(child,childI) in order.Children">
							<img v-if="childI <= 4" :src="child.GoodsThumb" :key="child.Id" />
						</template>
						<em v-if="order.Children.length > 5">
							5个商品
						</em>
					</div>
				</ul>
				<h5 style="font-size: 1.1rem;font-weight: normal;">总计:<span style="color: red;font-size: 1.7rem;">￥{{CountMoney}}</span></h5>
			</div>
			
			<div class="PayAct">
				
				<li class="Choose">
					
					<div @click="PayChannelChange('wxpay')">
						<em>
							<i v-if="PayChannel == 'wxpay'"></i>
						</em>
						<img :class="PayChannel != 'wxpay' ? 'Gray':''" src="../../assets/img/pay/wxpayico.png" />
						<span>微信支付</span>
					</div>
					<div @click="PayChannelChange('balance')">
						<em>
							<label v-if="PayChannel == 'balance'"></label>
						</em>
						<img :class="PayChannel != 'balance' ? 'Gray':''" src="../../assets/img/pay/balance.png" />
						<span>余额支付（当前余额￥{{MyBalance.Balance}}）</span>
					</div>
				</li>
				
				<div class="QrCode" v-if="PayChannel == 'wxpay'">
					<img v-if="WxpayQrCode != ''" :src="WxpayQrCode">
					<li v-if="WxpayQrCode != ''" :src="WxpayQrCode" style="padding-left: 28px;margin-bottom: 30px;margin-bottom: 10px;">请打开微信APP扫码支付
					</li>
					<div style="padding: 10px 20px;" @click="$Jump('/my/order/list')" v-if="IsSuccess">支付已成功，即将回到订单中心</div>
					
				</div>
				
				<div v-if="PayChannel == 'balance'" style="margin-top: 100px;">
					
					<a @click="balancePay()" style="background-color: rgba(0,164,254);color: #FFFFFF;padding: 20px 40px;margin-bottom: 20px;cursor: pointer;">
						<i class="el-icon-caret-right"></i>
						请点此确认使用账户余额支付￥{{CountMoney}}元</a>
					
				</div>
				
			
				
			</div>
			
			
			
		</div>
		
	</div>

	
</div>
<Footer></Footer>

	
</div>
</template>

<script>
	import {InputNumber,Checkbox,Select,Option,Dialog} from 'element-ui'
	export default {
	  name: 'Pay',
	  props: {
	  },
	  data() {
	      return {
			  OrderList:[],
			  CountMoney:0,
			  OrderIds:[],
			  PayChannel:'wxpay',//支付通道
			  WxpayQrCode:'',
			  AlipayUrl:'',
			  WxRecharegeId:'', //微信充值ID
			  AliRechargeId:'', //支付宝充值ID
			  IsSuccess:false,
			  MyBalance:{
				  Balance:0.00,
				  BlanceFrozen:0.00,
			  }
	      }
	    },
	  components: {
		  "el-input-number":InputNumber,
		    "el-checkbox":Checkbox,
			'el-select':Select,
			'el-option':Option,
			'el-dialog':Dialog,
	  },
	  created() {
	  	if(this.$store.getters.getToken == ''){
	  		this.$Jump('/')
			return
	  	}
		this.getPrepayList()
		this.getMyBlance()
	  },
	  methods:{
		  PayChannelChange(_paychanel){
			  this.PayChannel = _paychanel
			  if(this.PayChannel == 'alipay' && this.AlipayUrl == ''){
				  this.getAlipayQrCode()
			  }else if(this.PayChannel == 'wxpay' && this.WxpayQrCode == ''){
				  this.getWxpayQrCode()
			  }
		  },
		  getMyBlance(){
		  			  let data = {
		  			  	Service:'Order',
		  			  	Class: 'Balance',
		  			  	Action: 'MyBalance',
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		return
		  			  	}
		  				
						this.MyBalance = res.Data
		  				
		  				
		  			  })
		  			  
		  },
		  getPrepayList(){
			  
			  let idsStr = this.$route.params.OrderIds
			  if(!idsStr || idsStr.length < 32){
				  this.$message('非法的请求路径，禁止访问')
				  return
			  }
			  
			  this.OrderIds = idsStr.split(',')
			  
			  let data = {
			  	Service:'Order',
			  	Class: 'Order',
			  	Action: 'Prepay',
			  	Page:1,
			  	PageSize:this.OrderIds.length,
				OrderIds:JSON.stringify(this.OrderIds)
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
				
				if(res.Data.OrderList == null || res.Data.OrderList == undefined){
					this.OrderList = []
					this.$message('异常数据')
					return
				}
				
			  	this.OrderList = res.Data.OrderList
				this.CountMoney = 0.00
				for(var i=0;i<this.OrderList.length;i++){
					this.CountMoney += this.OrderList[i].TotalFee
				}
			  	this.CountMoney = this.CountMoney.toFixed(2)
				this.getWxpayQrCode()
				
			  })
			  
		  },
		getWxpayQrCode(){ //获取微信支付二维码
			
			if(this.CountMoney <= 0){
				return
			}
		
			let data = {
				Service:'Pay',
				Class: 'Wxpay',
				Action: 'Unifiedorder',
				Amount:parseInt(this.CountMoney*100),
			}
			if(this.OrderIds.length == 1){
				data.Subject = "订单付款"
 			}else if(this.OrderIds.length > 1){
				data.Subject = "乐尚云商订单合并付款"
 			}
			data.BaseInfo = JSON.stringify(this.OrderIds)
			data.Type = "order"
			data.Source = 'pc-c'
			data.Remark = data.Subject
			
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				
				this.WxpayQrCode = res.Data.QRCodeUrlImg
				
				let that = this
				setTimeout(function(){
					that.checkPayment(res.Data.RechargeId,0,1200)
				},10000)
				
			})
			.catch((res) => {
				this.$message('网络处理异常')
			})
		},
		getAlipayQrCode(){ //获取支付宝支付链接
		
			if(this.CountMoney <= 0){
				return
			}
		
			let data = {
				Service:'Pay',
				Class: 'Alipay',
				Action: 'Webpay',
				Amount:parseInt(this.CountMoney*100),
			}
			if(this.OrderIds.length == 1){
				data.Subject = "订单付款"
			}else if(this.OrderIds.length > 1){
				data.Subject = "乐尚云商订单合并付款"
			}
			data.Remark = data.Subject
			data.BaseInfo = JSON.stringify(this.OrderIds)
			data.Type = "order"
			data.Source = 'pc-c'
			
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				
				this.AlipayUrl = res.Data.PayUrl
				
				let that = this
				setTimeout(function(){
					that.checkPayment(res.Data.RechargeId,0,120)
				},10000)
				
			})
			.catch((res) => {
				this.$message('网络处理异常')
			})
		},
		checkPayment(_purchaseId,_times,_maxTime){ //验证当前页的商品是否已经被支付，每3秒验证一次
			
			if(this.IsSuccess){
				return
			}
			let data = {
				Service:'Pay',
				Class: 'FinanceRecharge',
				Action: 'StatusCheck',
				Id:_purchaseId,
				LoadingText:'none'
			}
			
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				let that = this
				if(res.ErrorId != 0 || res.Data.Status != 100){
					setTimeout(function(){
						that.checkPayment(_purchaseId,_times+1,_maxTime)
					},3000)
					return
				}
				
				//支付已成功，跳入订单页
				this.IsSuccess = true
				setTimeout(function(){
					that.$Jump('/my/order/list')
				},5000)
				
			})
			.catch((res) => {
				this.$message('网络处理异常')
			})
			
		},
		balancePay(){ //余额支付当前全部订单
			
			
			let data = {
				Service:'Pay',
				Class: 'Balance',
				Action: 'OrderPay',
				OrderIds:JSON.stringify(this.OrderIds),
				LoadingText:'none'
			}
			
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				let that = this
				if(res.ErrorId != 0){
					this.$message.error(res.Msg)
					return
				}
				
				//支付已成功，跳入订单页
				this.$message.success('余额支付成功')
				this.IsSuccess = true
				setTimeout(function(){
					that.$Jump('/my/order/list')
				},3000)
				
			})
			.catch((res) => {
				this.$message('网络处理异常')
			})
			
		}
		
	  }
	}
</script>

<style scoped>
	.BuyTitle{
		height: 45px;
		line-height: 45px;
		border-bottom: 1px solid rgba(0,0,0,0.06);
		width: 100%;

		font-size: 1.2rem;
		color: rgba(0,0,0,0.3);
	}
	.Address{
		width: 100%;
	}
	.Address .Title{
		display: flex;
		width: 100%;
		padding: 10px 0px;
		align-items: center;
	}
	.Address .Title h3{
		border-left: 2px solid rgba(23,134,65,1);
		display: inline-block;
		padding-left: 10px;
	}
	.Address .Title div{
		flex: 1;
		text-align: right;
	}
	.Address .Title div span{
		margin-left: 20px;
		cursor: pointer;
	}
	.Address .Title div span:hover{
		color: rgba(23,134,65,1);
	}
	.Address .List{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		overflow: hidden;
		height: 130px;
	}
	.Address .AllList{
		height: auto;
	}
	.Address .List .One{
		width: 24%;
		height: 80px;
		border: 1px solid rgba(0,0,0,0.08);
		margin-bottom: 15px;
		border-radius: 5px;
		padding: 20px 0px;
		position: relative;
		cursor: pointer;
	}
	.Address .List .One:hover{
		border: 1px solid rgba(23,134,65,1);
	}
	.Address .List .One li{
		line-height: 20px;
		font-size: 1.2rem;
		padding: 0px 20px;
	}
	.Address .List .One p{
		padding: 0px 20px;
	}
	.Address .List .One span{
		position: absolute;
		right: 0px;
		bottom: 0px;
		border-radius: 5px 0px 5px 0px;
		background-color: rgba(23,134,65,1);
		padding: 3px 5px;
		color: #FFFFFF;
	}
	.Address .List .One ul{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Address .List .One ul i{
		margin-right: 10px;
	}
	.Address .List .Empty{
		border-color: rgba(0,0,0,0);
		cursor: default;
	}
	.Address .List .Empty:hover{
		border-color: rgba(0,0,0,0);
	}
	.OrderTitle{
		display: flex;
		width: 100%;
		padding: 10px 0px;
		align-items: center;
	}
	.OrderTitle h3{
		border-left: 2px solid rgba(23,134,65,1);
		display: inline-block;
		padding-left: 10px;
	}
	.OrderTitle div{
		flex: 1;
		text-align: right;
	}
	
	.GoodsList{
		width: 100%;
	}
	.GoodsList .Hi{
		display: flex;
		width: 100%;
		padding: 15px 0px;
		border: 1px solid rgba(0,0,0,0.05);
		border-top: 0px;
		align-items: center;
	}
	.GoodsList .Hi:hover{
		background-color: rgba(23,134,65,0.04);
	}
	.GoodsList .Title{
		background-color: rgba(0,0,0,0.05);
	}
	.GoodsList .Hi span.CartSpan{
		text-align: center;
	}
	.GoodsList .C1{
		width: 60px;
		padding-left: 10px;
	}
	.GoodsList .C2{
		width: 120px;
		padding-left: 10px;
	}
	.GoodsList .C2 img{
		width: 80px;
	}
	.GoodsList .C3{
		flex: 1;
		padding-left: 10px;
	}
	.GoodsList .C3 p{
		margin-top: 0px;
		padding-right: 10px;
		color: #888;
		margin-bottom: 3px;
	}
	.GoodsList .C3 p i{
		font-style: normal;
		color: rgb(23,134,65,1);
	}
	.GoodsList .C3 em{
		color: rgba(0,0,0,0.3);
	}
	.GoodsList .C4,.GoodsList .C5,.GoodsList .C6,.GoodsList .C7{
		width: 120px;
		padding-left: 10px;
	}
	.GoodsList .C8{
		width: 120px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.C8 em{
		display: block;
		width: 100%;
	}
	.C8 em label{
		cursor: pointer;
	}
	
	.Count{
		margin-top: 20px;
		display: flex;
		width: 100%;
	}
	.Count .Info{
		flex: 1;
		border: 1px solid rgba(0,0,0,0.04);
		border-right-width: 0px;
		display: flex;
		padding: 10px;
		align-items: center;
	}
	.Count .Info span{
		margin-left: 15px;
		cursor: pointer;
	}
	.Count .Info div{
		flex: 1;
		text-align: right;
	}
	.Count .Info div em{
		margin-left: 10px;
		margin-right: 10px;
	}
	.Count .Info div i{
		color: rgba(23,134,65,1);
		font-style: normal;
	}
	.Count .Info div label{
		font-size: 1.6rem;
		color: rgba(23,134,65,1);
	}
	.Count .Act{
		background-color: rgba(23,134,65,1);
		color: #FFFFFF;
		width: 200px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	
	.BuyTitle{
		display: flex;
		align-items: center;
	}
	.BuyTitle em{
		flex: 1;
	}
	.BuyTitle span{
		font-size: 0.9rem;
	}
	
	.PayArea{
		display: flex;
	}
	.PayArea .Orders{
		width: 250px;
		padding-right: 20px;
		padding-left: 20px;
		min-height: 300px;
		background-color: rgba(0,0,0,0.04);
	}
	.PayArea .Orders ul{
		padding-bottom: 10px;
		border-bottom: 1px solid rgba(0,0,0,.03);
	}
	.PayArea .PayAct{
		flex: 1;
		border-left: 1px solid rgba(0,0,0,0.04);
		padding-left: 20px;
	}
	
	.DetailImgs{
		display: flex;
		align-items: center;
	}
	.DetailImgs img{
		width: 50px;
		height: 50px;
		margin-right: 10px;
	}
	.DetailImgs em{
		height: 50px;
		line-height: 50px;
		padding: 0px 10px;
		text-align: center;
		color: rgba(0,0,0,0.3);
	}
	.Choose{
		display: flex;
	}
	.Choose div{
		display: flex;
		align-items: center;
		margin-top: 10px;
		margin-right: 40px;
		cursor: pointer;
		padding: 10px;
	}
	.Choose div:hover{
		background-color: rgba(0,0,0,0.1);
	}
	.Choose div span{
		font-size: 1.2rem;
	}
	.Choose div em{
		display: block;
		width: 16px;
		height: 16px;
		border-radius: 8px;
		background-color: rgba(0,0,0,0.2);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
	}
	.Choose div em i{
		display: block;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: rgba(0,183,0,1);
	}
	.Choose div em label{
		display: block;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: rgba(2,168,246,1);
	}
	.Choose div img{
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}
	.Choose div img.Gray{
		-webkit-filter: grayscale(100%); /* webkit */
		-moz-filter: grayscale(100%); /*firefox*/
		-ms-filter: grayscale(100%); /*ie9*/
		-o-filter: grayscale(100%); /*opera*/
		filter: grayscale(100%);
		filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1); 
		filter:gray; /*ie9- */
	}
	.Choose div  li{
		
	}
</style>
